<template>
  <!-- Content Wrapper. Contains page content -->
  <div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>Water Consumption Book</h1>
          </div><!-- /.col -->
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">Home</li>
              <li class="breadcrumb-item">Reports</li>
              <li class="breadcrumb-item active">Consumption Book</li>
            </ol>
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <!-- /.content-header -->

    <!-- Main content -->
    <div class="content">
      <div class="container-fluid">
        <div class="row">
          <!-- /.col-md-6 -->
          <div class="col-lg-12">
            <div class="card">
              <Loader v-if="loading" style="height: 100%" />
              <div class="card-header container-fluid">

                <span class="mr-2">
                  <button title="Search By" type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                    {{this.reading_year}}
                  </button>
                  <ul class="dropdown-menu cursor" x-placement="bottom-start" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, 48px, 0px);">
                    <li v-for="year in years" :value="year" :key="year" class="dropdown-item ddown" @click="loadData(year, reading_month, reading_cluster)">{{ year }}</li>
                  </ul>
                </span>
                <span class="mr-2">
                  <button title="Search By" type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                    <!--<span v-if="this.transaction_month=='00'">- Month -</span>-->
                    <span v-if="this.reading_month=='01'">January</span>
                    <span v-if="this.reading_month=='02'">February</span>
                    <span v-if="this.reading_month=='03'">March</span>
                    <span v-if="this.reading_month=='04'">April</span>
                    <span v-if="this.reading_month=='05'">May</span>
                    <span v-if="this.reading_month=='06'">June</span>
                    <span v-if="this.reading_month=='07'">July</span>
                    <span v-if="this.reading_month=='08'">August</span>
                    <span v-if="this.reading_month=='09'">September</span>
                    <span v-if="this.reading_month=='10'">October</span>
                    <span v-if="this.reading_month=='11'">November</span>
                    <span v-if="this.reading_month=='12'">December</span>
                  </button>
                  <ul class="dropdown-menu cursor" x-placement="bottom-start" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, 48px, 0px);">
                    <!--<li value="00" class="dropdown-item ddown" @click="loadDataPayment(transaction_year, '00')">- Month - </li>-->
                    <li value="01" class="dropdown-item ddown" @click="loadData(reading_year, '01', reading_cluster)">January</li>
                    <li value="02" class="dropdown-item ddown" @click="loadData(reading_year, '02', reading_cluster)">February</li>
                    <li value="03" class="dropdown-item ddown" @click="loadData(reading_year, '03', reading_cluster)">March</li>
                    <li value="04" class="dropdown-item ddown" @click="loadData(reading_year, '04', reading_cluster)">April</li>
                    <li value="05" class="dropdown-item ddown" @click="loadData(reading_year, '05', reading_cluster)">May</li>
                    <li value="06" class="dropdown-item ddown" @click="loadData(reading_year, '06', reading_cluster)">June</li>
                    <li value="07" class="dropdown-item ddown" @click="loadData(reading_year, '07', reading_cluster)">July</li>
                    <li value="08" class="dropdown-item ddown" @click="loadData(reading_year, '08', reading_cluster)">August</li>
                    <li value="09" class="dropdown-item ddown" @click="loadData(reading_year, '09', reading_cluster)">September</li>
                    <li value="10" class="dropdown-item ddown" @click="loadData(reading_year, '10', reading_cluster)">October</li>
                    <li value="11" class="dropdown-item ddown" @click="loadData(reading_year, '11', reading_cluster)">November</li>
                    <li value="12" class="dropdown-item ddown" @click="loadData(reading_year, '12', reading_cluster)">December</li>
                  </ul>
                </span>
                <span class="mr-2">
                  <button title="Barangay" type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                    <!--<span v-if="this.transaction_month=='00'">- Month -</span>-->
                    <span v-if="this.reading_cluster=='A'">As-is</span>
                    <span v-if="this.reading_cluster=='B'">Balayong</span>
                    <span v-if="this.reading_cluster=='L'">Lynville</span>
                    <span v-if="this.reading_cluster=='AM'">Amaia</span>
                  </button>
                  <ul class="dropdown-menu cursor" x-placement="bottom-start" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, 48px, 0px);">
                    <!--<li value="00" class="dropdown-item ddown" @click="loadDataPayment(transaction_year, '00')">- Month - </li>-->
                    <li value="A" class="dropdown-item ddown" @click="loadData(reading_year, reading_month, 'A')">As-is</li>
                    <li value="B" class="dropdown-item ddown" @click="loadData(reading_year, reading_month, 'B')">Balayong</li>
                    <li value="L" class="dropdown-item ddown" @click="loadData(reading_year, reading_month, 'L')">Lynville</li>
                    <li value="AM" class="dropdown-item ddown" @click="loadData(reading_year, reading_month, 'AM')">Amaia</li>
                  </ul>
                </span>

                <download-excel
                  class="btn btn-default pull-right"
                  :fetch = "fetchExcelData"
                  :fields="json_fields"
                  :before-generate = "startDownload"
                  :before-finish = "finishDownload"
                  :header="header"
                  :worksheet="worksheet"
                  :name="filename"
                  :footer="footer"
                  v-show='this.npreadings.length != 0'
                  v-tooltip="{ content: '<b>Export to Excel</b>', placement: 'left', classes: ['info'], targetClasses: ['it-has-a-tooltip'], offset: 10, delay: { show: 500, hide: 300, }, }"
                >
                  <i class="fa fa-file-excel-o" aria-hidden="true"></i>
                </download-excel>

              
            </div>
            <div v-if="loading" class="card-body p-0">
               <table class="table responsive-table table-striped table-bordered">
                <thead>
                  <tr>
                    <td colspan="5" class="border-bottom" style="text-align: center">
                      <b>WATER CONSUMPTION BOOK</b><br/>
                      <b>For the month of
                      <span v-if="this.reading_month=='01'"><b>January</b></span>
                      <span v-else-if="this.reading_month=='02'"><b>February</b></span>
                      <span v-else-if="this.reading_month=='03'"><b>March</b></span>
                      <span v-else-if="this.reading_month=='04'"><b>April</b></span>
                      <span v-else-if="this.reading_month=='05'"><b>May</b></span>
                      <span v-else-if="this.reading_month=='06'"><b>June</b></span>
                      <span v-else-if="this.reading_month=='07'"><b>July</b></span>
                      <span v-else-if="this.reading_month=='08'"><b>August</b></span>
                      <span v-else-if="this.reading_month=='09'"><b>September</b></span>
                      <span v-else-if="this.reading_month=='10'"><b>October</b></span>
                      <span v-else-if="this.reading_month=='11'"><b>November</b></span>
                      <span v-else-if="this.reading_month=='12'"><b>December</b></span>
                      {{ reading_year }}</b><br/>
                      <span v-if="this.reading_cluster=='A'"><b>AS-IS</b></span>
                      <span v-else-if="this.reading_cluster=='B'"><b>BALAYONG</b></span>
                      <span v-else-if="this.reading_cluster=='L'"><b>LYNVILLE</b></span>
                      <span v-else-if="this.reading_cluster=='AM'"><b>AMAIA</b></span>
                    </td>
                  </tr>
                  <tr>
                    <th scope="col">CONSUMER NUMBER</th>
                    <th scope="col">NAME</th>
                    <th scope="col">WATER CONSUMED</th>
                    <th scope="col">AMOUNT</th>
                    <th scope="col">STATUS</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colspan="5">
                      <div class="row justify-content-center mt-5">
                          <svg width="64" height="41" viewBox="0 0 64 41" xmlns="http://www.w3.org/2000/svg">
                            <g transform="translate(0 1)" fill="none" fill-rule="evenodd">
                              <ellipse fill="#F5F5F5" cx="32" cy="33" rx="32" ry="7"></ellipse>
                              <g fill-rule="nonzero" stroke="#D9D9D9">
                                <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
                                <path d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z" fill="#FAFAFA"></path>
                              </g>
                            </g>
                          </svg>                  
                      </div>
                      <div class="row justify-content-center mb-5 text-muted">
                        <p>Loading</p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-else class="card-body p-0">
               <table class="table responsive-table table-striped table-bordered">
                <thead>
                  <tr>
                    <td colspan="7" class="border-bottom" style="text-align: center">
                      <b>WATER CONSUMPTION BOOK</b><br/>
                      <b>For the month of
                      <span v-if="this.reading_month=='01'"><b>January</b></span>
                      <span v-else-if="this.reading_month=='02'"><b>February</b></span>
                      <span v-else-if="this.reading_month=='03'"><b>March</b></span>
                      <span v-else-if="this.reading_month=='04'"><b>April</b></span>
                      <span v-else-if="this.reading_month=='05'"><b>May</b></span>
                      <span v-else-if="this.reading_month=='06'"><b>June</b></span>
                      <span v-else-if="this.reading_month=='07'"><b>July</b></span>
                      <span v-else-if="this.reading_month=='08'"><b>August</b></span>
                      <span v-else-if="this.reading_month=='09'"><b>September</b></span>
                      <span v-else-if="this.reading_month=='10'"><b>October</b></span>
                      <span v-else-if="this.reading_month=='11'"><b>November</b></span>
                      <span v-else-if="this.reading_month=='12'"><b>December</b></span>
                      {{ reading_year }}</b><br/>
                      <span v-if="this.reading_cluster=='A'"><b>AS-IS</b></span>
                      <span v-else-if="this.reading_cluster=='B'"><b>BALAYONG</b></span>
                      <span v-else-if="this.reading_cluster=='L'"><b>LYNVILLE</b></span>
                      <span v-else-if="this.reading_cluster=='AM'"><b>AMAIA</b></span>
                    </td>
                  </tr>
                  <tr>
                    <th scope="col">CONSUMER NUMBER</th>
                    <th scope="col">NAME</th>
                    <th scope="col">PREVIOUS</th>
                    <th scope="col">PRESENT</th>
                    <th scope="col">WATER CONSUMED</th>
                    <th scope="col">AMOUNT</th>
                    <th scope="col">STATUS</th>
                  </tr>
                </thead>
                <tbody v-if="readings.total==0">
                  <tr>
                    <td colspan="7">
                      <div class="row justify-content-center mt-5">
                          <svg width="64" height="41" viewBox="0 0 64 41" xmlns="http://www.w3.org/2000/svg">
                            <g transform="translate(0 1)" fill="none" fill-rule="evenodd">
                              <ellipse fill="#F5F5F5" cx="32" cy="33" rx="32" ry="7"></ellipse>
                              <g fill-rule="nonzero" stroke="#D9D9D9">
                                <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
                                <path d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z" fill="#FAFAFA"></path>
                              </g>
                            </g>
                          </svg>                  
                      </div>
                      <div class="row justify-content-center mb-5">
                        <p>No Records Found</p>
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr v-for="reading in readings.data" :key="reading.id">
                    <th scope="row">{{ reading.consumer.Consumercode }}</th>
                    <td data-title="NAME">{{ reading.consumer.Fullname }}</td>
                    <td data-title="PREVIOUS">{{ reading.prev_reading }}</td>
                    <td data-title="PRESENT">{{ reading.pres_reading }}</td>
                    <td data-title="WATER CONSUMED">{{ reading.pres_reading - reading.prev_reading }}</td>
                    <td data-title="AMOUNT">{{ reading.currentcharge | toMoney }}</td>
                    <td data-title="STATUS" v-if="!reading.transactions.length">Unpaid</td>
                    <td data-title="STATUS" v-else>Paid</td>
                  </tr>
                </tbody>
              </table>
              </div>
              <div class="card-footer">
                <div class="row col-12 p-0 m-0">
                    <div class="col-7 p-0">
                      <pagination :data="readings" @pagination-change-page="getResults" show-disabled :limit="1"></pagination>
                    </div>
                    <div class="text-right col-5 p-0">
                      <b><span class="badge bg-info">TOTAL CONSUMPTION: {{ compute_pres_consumption(npreadings) - compute_prev_consumption(npreadings) }}</span> <span class="badge bg-green">TOTAL AMOUNT: {{ compute_total_amount(npreadings) | toMoney }}</span></b>
                    </div>
                    
                </div>
              </div>

              </div>
 

          </div>
          <!-- /.col-md-6 -->
        </div>
        <!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <!-- /.content -->
  </div>
  <!-- /.content-wrapper -->
</template>

<script>

import {mapGetters} from 'vuex'
import axios from 'axios'
import Loader from '@/components/Loader'
import moment from 'moment';
import JsonExcel from "vue-json-excel";

export default {
  name: 'Consumption',

  components: {
    Loader,JsonExcel
  },

  data() {
    return {
      loading: false,
      reading_year: '',
      reading_month: '',
      reading_cluster: '',
      total_prev: '',
      total_pres: '',
      total_amt: '',
      readings : {},
      npreadings : [],
      json_fields: {
        "CONSUMER NUMBER": 'consumer.Consumercode',
        "NAME": 'consumer.Fullname',
        "PREVIOUS": 'prev_reading',
        "PRESENT": 'pres_reading',
        "WATER CONSUMED": 'consumption',
        "AMOUNT": 'currentcharge',
        "STATUS": { 
          field: 'transactions.length',
          callback: (value) => {
            if (value != 0) {
              return 'Paid';
            } else {
              return 'Unpaid';
            }
          },
        }
      },
      header: [],
      filename: '',
      footer: '',
      worksheet: '',
      //
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],

    }
  },

  computed: {
    ...mapGetters(['user']),

    years() {
      const year = new Date().getFullYear()
      return Array.from({length: year - 2019}, (value, index) => 2020 + index)
    },
  },

  methods: {

    compute_pres_consumption(consumption) {
      if (!consumption) {
        //
      } else {
        return consumption.reduce((tot, val) => {
          var sum;
          sum = tot + parseInt(val.pres_reading);
          this.total_pres = sum;
          return sum;
        }, 0);
      }
    },

    compute_prev_consumption(consumption) {
      if (!consumption) {
        //
      } else {
        return consumption.reduce((tot, val) => {
          var sum;
          sum = tot + parseInt(val.prev_reading);
          this.total_prev = sum;
          return sum;
        }, 0);
      }
    },

    compute_total_amount(consumption) {
      if (!consumption) {
        //
      } else {
        return consumption.reduce((tot, val) => {
          var sum;
          sum = tot + parseFloat(val.currentcharge.replace(/,/g, ''));
          this.total_amt = sum;
          return sum;
        }, 0);
      }
    },

    getResults(page = 1) {
      this.$Progress.start();
      axios.get('api/readings/'+ this.reading_year + '/' + this.reading_month + '/' + this.reading_cluster + '?page=' + page).then(({ data }) => (this.readings = data));
      this.$Progress.finish();
    },

    async loadData(year, month, cluster) {
        this.loading = true;
        this.reading_year = year;
        this.reading_month = month;
        this.reading_cluster = cluster;
        let page = this.readings.current_page;
        await axios.get('api/readings/'+ year + '/' + month + '/' + cluster + '?page=' + page).then(({ data }) => (this.readings = data));
        await axios.get('api/readings/np/'+ year + '/' + month + '/' + cluster).then(({ data }) => (this.npreadings = data));
        //this.fetchExcelData(year, month, cluster)
        this.loading = false;
    },

    async fetchExcelData(){
      var month;
      var cluster;

      if(this.reading_month == '01') {
        month = 'JANUARY';
      } else if (this.reading_month == '02') {
        month = 'FEBRUARY';
      } else if (this.reading_month == '03') {
        month = 'MARCH';
      } else if (this.reading_month == '04') {
        month = 'APRIL';
      } else if (this.reading_month == '05') {
        month = 'MAY';
      } else if (this.reading_month == '06') {
        month = 'JUNE';
      } else if (this.reading_month == '07') {
        month = 'JULY';
      } else if (this.reading_month == '08') {
        month = 'AUGUST';
      } else if (this.reading_month == '09') {
        month = 'SEPTEMBER';
      } else if (this.reading_month == '10') {
        month = 'OCTOBER';
      } else if (this.reading_month == '11') {
        month = 'NOVEMBER';
      } else if (this.reading_month == '12') {
        month = 'DECEMBER';
      }

      if(this.reading_cluster == 'A') {
        cluster = 'AS-IS';
      } else if (this.reading_cluster == 'B') {
        cluster = 'BALAYONG';
      } else if (this.reading_cluster == 'L') {
        cluster = 'LYNVILLE';
      } else if (this.reading_cluster == 'AM') {
        cluster = 'AMAIA';
      }

      const response = await axios.get('api/readings/np/'+ this.reading_year + '/' + this.reading_month + '/' + this.reading_cluster);
      this.header = ['WATER CONSUMPTION BOOK', 'FOR THE MONTH OF ' +  month +  ' ' + this.reading_year, cluster, '  ']
      this.worksheet = cluster + ' - ' +  month + ' ' + this.reading_year;
      this.filename = 'WATER_CONSUMPTION_BOOK_'+ this.reading_cluster +'_'+ this.reading_year +'_' + this.reading_month + '.xls';
      this.footer = ['  ', 'TOTAL CONSUMPTION: ' + (this.total_pres - this.total_prev), 'TOTAL AMOUNT: ' + (this.total_amt)];
      //console.log(this.footer);
      return response.data;
    },
    startDownload(){
        this.$Progress.start();
    },
    finishDownload(){
        this.$Progress.finish();
    }

  },

  mounted() {
    this.$Progress.start();
    const current = new Date();
    this.reading_year = current.getFullYear();
    this.reading_month = moment(String(current)).format('MM');
    this.reading_cluster = 'A';
    this.loadData(this.reading_year, this.reading_month, this.reading_cluster);
    //this.fetchExcelxData(this.reading_year, this.reading_month, this.reading_cluster);
    this.$Progress.finish();
    
  },

}
</script>


<style scoped>
.cursor {
  cursor: pointer;
}
</style>
